import { graphql } from 'relay-runtime';

const HomePage = graphql`
  query HomePageQuery($username: String!, $url: String!) {
    userProfileByUsername(username: $username) {
      id
      visitorCanEdit
      userReviews {
        edges {
          node {
            id
            onlyVisibleToRecipient
          }
        }
      }
      ...UserProfile_userProfile
      ...Projects_userProfile
      ...Services_userProfile
      ...UserReviews_userProfile
      ...Footer_userProfile
    }
    ...UrlMetaData_queryFragment
    ...IndependentPortfolioTemplates_queryFragment
  }
`;

export default HomePage;
